export enum AppUrls {
  DEVELOPMENT = 'https://dev.client.alpha-abs.co.uk',
  STAGING = 'https://stage.portal.alpha.co.uk',
  LIVE = 'https://portal.alpha.co.uk',
  PARALLEL = 'https://parallel.portal.alpha.co.uk',
  PREPROD = 'https://preprod.portal.alpha.co.uk',
  PERF = 'https://perf.portal.alpha.co.uk',
  DEMO = 'https://demo.portal.alpha.co.uk',
  EXTERNAL = 'https://external.portal.alpha.co.uk',
}

enum FormioStages {
  DEV = 'https://dev-formio-onboarding.dev01.alpha.co.uk/develop-lssjknroetohvht/',
  AUTHORING = 'https://preprod-formio-onboarding.preprod01.alpha.co.uk/authoring-lssjknroetohvht/',
  LIVE = 'https://formio-onboarding.prod01.alpha.co.uk/lssjknroetohvht/',
}

enum FormioDefaultFormId {
  DEV = '64d23696a499d80663b3dabb',
  AUTHORING = '64d2089f8560ad527364e7e9',
  LIVE = '64db2d0feb1d2c3c1aa2fe89',
}

enum FormIoOnboardingFormApiName {
  DEV = 'onboardingreducedsecure',
  AUTHORING = 'onboardingreducedsecure',
  LIVE = 'onboardingreducedsecure',
}

enum FormIoReusableKycFormApiName {
  DEV = 'reusablekycentityonboarding',
  AUTHORING = 'reusablekycentityonboarding',
  LIVE = 'reusablekycentityonboarding',
}

enum FormIoSecureReducedForm {
  DEV = '65670d95f67b7f26ba6a0a48',
  AUTHORING = '656767c9865b1eee6bb88bf5',
  LIVE = '656767eeb19e900145486878',
}

enum FormIoReusableKycFormId {
  DEV = '66752ce7d3f477c48edca0ad',
  AUTHORING = '66753c83696b0116a576e330',
  LIVE = '6675877e48dcaf81ab0033b1',
}

enum FormioMagicLinkLogin {
  DEV = '655f094fc48c7fb80aac2e73',
  AUTHORING = '656767cc865b1eee6bb88c73',
  LIVE = '656767eab19e9001454867cb',
}
enum FormioMagicLinkRegister {
  DEV = '6555fa448b7d19d34f44a562',
  AUTHORING = '656767cd865b1eee6bb88cac',
  LIVE = '656767ebb19e900145486803',
}

enum HotjarSiteID {
  LIVE = 3858502,
}

interface CognitoConfig {
  poolId: string;
  clientId: string;
  storage: Storage;
  authenticationFlowType?: 'CUSTOM_AUTH';
}

interface DataDogConfig {
  appId: string;
  clientToken: string;
  service: string;
}

enum PublicFileCloudFrontDistribution {
  DOMAIN_NAME = 'https://d1vcta0hdez2ni.cloudfront.net/',
}

interface EnvironmentConfig {
  REACT_APP_DATA_DOG: DataDogConfig;
  REACT_APP_API_URL: string;
  REACT_APP_COGNITO: CognitoConfig;
  REACT_APP_HTTP_MOCKS: boolean;
  FORMIO_FORM_URL: FormioStages;
  FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId; // TODO: Think this is deprecated
  FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName;
  FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName;
  FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm;
  FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId;
  FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister;
  FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin;
  CLOUDFRONT_DISTRIBUTION_DOMAIN: string;
  HOTJAR_SITE_ID: HotjarSiteID;
  HOTJAR_ENABLED: boolean;
}

// Default is development
// eslint-disable-next-line import/no-mutable-exports
let environmentVars: EnvironmentConfig = {
  REACT_APP_API_URL: 'https://api.dev.client.alpha-abs.co.uk/',
  REACT_APP_COGNITO: {
    poolId: 'eu-west-1_jXwXCz5l2',
    clientId: '1m62je1steggs5rf4jbnpqbvgs',
    storage: localStorage,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  REACT_APP_DATA_DOG: {
    appId: '82e839a6-c88b-4762-b0f6-bc27a4dd994b',
    clientToken: 'pube1554f4782546d3c6e7261382ee9b2de',
    service: 'dev.client.alpha-abs',
  },
  REACT_APP_HTTP_MOCKS: false,
  FORMIO_FORM_URL: FormioStages.DEV,
  FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.DEV,
  FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.DEV,
  FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.DEV,
  FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.DEV,
  FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.DEV,
  FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.DEV,
  FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.DEV,
  CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
  HOTJAR_SITE_ID: HotjarSiteID.LIVE,
  HOTJAR_ENABLED: false,
};

const currentWindowLocation: string = window.location.href;
if (currentWindowLocation.indexOf(AppUrls.LIVE) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_Qp07DrjzT',
      clientId: '4pfbv87jhu2imt5lpuvojopav8',
      storage: sessionStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: 'df9a56a6-232a-45b5-9647-86685f7703be',
      clientToken: 'pub6beb070593d800eee4f12a9d5989523c',
      service: 'abs-alpha-live-portal',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.LIVE,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.LIVE,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.LIVE,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.LIVE,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.LIVE,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.LIVE,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.LIVE,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.LIVE,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: true,
  };
} else if (currentWindowLocation.indexOf(AppUrls.STAGING) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.stage.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_sYK28Gr4z',
      clientId: '2er2b8p2k5dkgnmb2psb3qic9k',
      storage: sessionStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: '214b6e39-9257-42c3-9bdf-d40676f34818',
      clientToken: 'pubc59dde384708a0da09987f0c4ab5513a',
      service: 'stage.portal.alpha',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.DEV,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.DEV,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.DEV,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.DEV,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.DEV,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.DEV,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.DEV,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.DEV,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: false,
  };
} else if (currentWindowLocation.indexOf(AppUrls.PARALLEL) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.parallel.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_95CoeR7EJ',
      clientId: '49vu80860srn2jic5ttjcc3m9j',
      storage: sessionStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: '34765f70-c11f-4835-b33e-fdb4ab7245fb',
      clientToken: 'pub6cba66e69159ca30587e12eb635be30f',
      service: 'parallel.portal.alpha',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.AUTHORING,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.AUTHORING,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.AUTHORING,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.AUTHORING,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.AUTHORING,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.AUTHORING,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.AUTHORING,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.AUTHORING,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: false,
  };
} else if (currentWindowLocation.indexOf(AppUrls.PREPROD) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.preprod.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_lZ4KhDbUf',
      clientId: '6f95hblbgq54ao7o5lsvam9qpd',
      storage: localStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: '84bb5a59-7f39-4372-a0c6-e5dfa60b5b81',
      clientToken: 'pub7f14df6e4798378478df6a16a08af947',
      service: 'preprod.portal.alpha',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.AUTHORING,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.AUTHORING,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.AUTHORING,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.AUTHORING,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.AUTHORING,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.AUTHORING,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.AUTHORING,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.AUTHORING,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: false,
  };
} else if (currentWindowLocation.indexOf(AppUrls.PERF) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.perf.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_QAdDdg6gt',
      clientId: '2r72scil961sq0be3m8nvj7i92',
      storage: sessionStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: '58247ac3-f181-43ee-a87a-2fc53e2599f6',
      clientToken: 'pub8abeb87f9d37c7a5ba5aaf414e9d02f1',
      service: 'perf.portal.alpha',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.AUTHORING,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.AUTHORING,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.AUTHORING,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.AUTHORING,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.AUTHORING,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.AUTHORING,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.AUTHORING,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.AUTHORING,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: false,
  };
} else if (currentWindowLocation.indexOf(AppUrls.DEMO) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.demo.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_reLHYCgzr',
      clientId: '58id2c2nudm520fqmrcu0fbqgt',
      storage: sessionStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: 'ba72860d-dd32-497b-a5fe-e7315e0234f2',
      clientToken: 'pub83d3e78250789e1c1aecb266a1737bf6',
      service: '/demo.portal.alpha',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.AUTHORING,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.AUTHORING,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.AUTHORING,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.AUTHORING,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.AUTHORING,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.AUTHORING,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.AUTHORING,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.AUTHORING,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: false,
  };
} else if (currentWindowLocation.indexOf(AppUrls.EXTERNAL) >= 0) {
  environmentVars = {
    REACT_APP_API_URL: 'https://api.external.portal.alpha.co.uk/',
    REACT_APP_COGNITO: {
      poolId: 'eu-west-1_tI4iz52Se',
      clientId: 'svj9up1uurp9bvaoil0odtvt8',
      storage: sessionStorage,
      authenticationFlowType: 'CUSTOM_AUTH',
    },
    REACT_APP_DATA_DOG: {
      appId: '348b6ee5-7a7f-47b4-afa3-e36c9da5da75',
      clientToken: 'pubedcd2f7f75375c2832217317b00d9742',
      service: 'external.portal.alpha',
    },
    REACT_APP_HTTP_MOCKS: false,
    FORMIO_FORM_URL: FormioStages.AUTHORING,
    FORMIO_DEFAULT_FORM_ID: FormioDefaultFormId.AUTHORING,
    FORMIO_ONBOARDING_FORM_API_NAME: FormIoOnboardingFormApiName.AUTHORING,
    FORMIO_REUASABLE_KYC_ONBOARDING_FORM_API_NAME: FormIoReusableKycFormApiName.AUTHORING,
    FORMIO_MAGIC_LINK_REGISTER_ID: FormioMagicLinkRegister.AUTHORING,
    FORMIO_MAGIC_LINK_LOGIN_ID: FormioMagicLinkLogin.AUTHORING,
    FORMIO_SECURE_REDUCED_FORM_ID: FormIoSecureReducedForm.AUTHORING,
    FORMIO_REUSABLE_KYC_ONBOARDING_FORM_ID: FormIoReusableKycFormId.AUTHORING,
    CLOUDFRONT_DISTRIBUTION_DOMAIN: PublicFileCloudFrontDistribution.DOMAIN_NAME,
    HOTJAR_SITE_ID: HotjarSiteID.LIVE,
    HOTJAR_ENABLED: false,
  };
}

export default environmentVars;
