import { CssBaseline } from '@mui/material';
import React, { useEffect, useLayoutEffect } from 'react';
import { SnackbarProvider } from 'components/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, Route, Router, Switch,
} from 'react-router-dom';
import { Flags, FlagsProvider } from 'react-feature-flags';
import { NotFoundPageNoRedirect } from 'components/NotFoundPage';
import BetaRedirectModal from 'components/Modals/BetaRedirectModal';
import useStyles from './App.styles';
import { Page } from './components/Layout/Page';
import { Notifier } from './components/Notifier';
import { Beneficiaries } from './domain/Beneficiaries';
import { CurrencyAccounts } from './domain/CurrencyAccounts';
import InactivityTracker from './domain/InactivityTracker/InactivityTracker';
import Payments from './domain/Payments/Payments';
import { Profile } from './domain/Profile';
import { Transactions } from './domain/Transactions';
import { TransactionsMultiEntity } from './domain/TransactionsMultiEntity';
import './Global.scss';
import routes from './routes.path';
import PrivateRoute from './routes/PrivateRoute';
import history from './services/history/browserHistory';
import { actions } from './store/initialPath/reducer';
import Auth from './domain/Auth';
import Authy from './domain/Authy/Authy';
import { Invoices } from './domain/Invoices';
import useAuthorization from './hooks/useAuthorization';
import { UserRole } from './models/user';
import useSwitchAccount from './hooks/useSwitchAccount';
import OutstandingActionsDashboard from './domain/OutstandingActionsDashboard';
import OutstandingActions from './domain/OutstandingActionsDashboard/OutstandingActions';
import useGetFeatureFlags from './hooks/useGetFeatureFlags';
import PaymentsMultiEntity from './domain/PaymentsMultiEntity/PaymentsMultiEntity';
import useInvoicePermissions from './hooks/useInvoicePermissions';
import { TStore } from './store';
import UserManagement from './domain/UserManagement';

import AuthForm from './domain/Onboarding/OnboardingForm/MagicLinkAuth';
import SubmissionsScreen from './domain/Onboarding/OnboardingForm/ListSubmissions';

const App: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const path = history.location.pathname;
  const invoiceAuthorization = useAuthorization([[UserRole.INVOICEMANAGER]]);
  const { getUserInvoiceEntities } = useInvoicePermissions();

  const IsAuthorizedToViewInvoices = useSelector<TStore, boolean>(
    (state) => state.outstandingDashboardTables.isAuthorizedToViewInvoices,
  );

  const { currentAccount } = useSwitchAccount();
  const { flags, requestGetFeatureFlags } = useGetFeatureFlags();

  // This is used to redirect user to previous route if they wasn't logged in
  useLayoutEffect(() => {
    if (!path.includes(routes.auth.base)) {
      dispatch(actions.updateInitialPath(path));
    }
  }, []);

  useEffect(() => {
    requestGetFeatureFlags();
    getUserInvoiceEntities();
  }, []);

  return (
    <FlagsProvider value={flags}>
      <SnackbarProvider
        maxSnack={6}
        classes={{ variantSuccess: classes.success }}
      >
        <CssBaseline />
        <Router history={history}>
          <Switch>
            <Route
              exact
              path={routes.onboardingForm.auth}
              render={() => (
                <AuthForm />
              )}
            />
            <Route
              exact
              path={routes.onboardingForm.submissions}
              render={() => (
                <SubmissionsScreen />
              )}
            />
            <Route
              exact
              path={routes.base}
              render={() => (
                <Flags
                  authorizedFlags={['outstandingDashboard']}
                  renderOn={() => <Redirect to={routes.dashboard.base} />}
                  renderOff={() => <Redirect to={routes.currencyAccounts} />}
                />
              )}
            />
            <Route
              exact
              path={routes.onboardingForm.redirect}
              render={() => (
                <Redirect to={routes.onboardingForm.auth} />
              )}
            />
            <Route
              exact
              path={[routes.onboardingForm.base, routes.onboardingForm.draft]}
              render={() => (
                <Redirect to={routes.onboardingForm.auth} />
              )}
            />
            <PrivateRoute path={routes.home}>
              <Authy />
              <InactivityTracker>
                <Page>
                  <Flags authorizedFlags={['betaRouting']}>
                    <BetaRedirectModal
                      open
                      title="Please Start Using the New Alpha Portal"
                      handleRedirect={() => { window.location.href = 'https://beta.portal.alpha.co.uk/auth/login'; }}
                    >
                      <>
                        <br />
                        <div>
                          Thank you for participating in our pilot program for the
                          upcoming release
                          of the Alpha Banking Portal.
                        </div>
                        <div>
                          You can now access the new portal,
                          which features a refreshed look and enhanced functionality.
                          <br />
                          <br />
                          Please visit the new portal at
                          <a href="https://beta.portal.alpha.co.uk/"> https://beta.portal.alpha.co.uk/</a>
                          .
                        </div>
                        <br />
                        <div>
                          <b>What You Need to Know:</b>
                        </div>
                        <div>
                          <ul>
                            <li>
                              The new portal retains all the features you&#8217;re
                              familiar with.
                            </li>
                            <li>Your login credentials remain unchanged.</li>
                            <li>Your feedback during this pilot phase is invaluable to us.</li>
                            <li>
                              For any questions or assistance, please contact
                              Client Services.
                            </li>
                          </ul>
                          Click the button below to access the new portal.
                        </div>
                      </>
                    </BetaRedirectModal>
                  </Flags>
                  <Switch>
                    <Route
                      exact
                      path={[routes.home]}
                      render={() => (
                        <Flags
                          authorizedFlags={['outstandingDashboard']}
                          renderOn={() => <OutstandingActionsDashboard />}
                          renderOff={() => <CurrencyAccounts />}
                        />
                      )}
                    />
                    <Route
                      exact
                      path={[routes.currencyAccounts]}
                      render={() => (
                        <Flags
                          authorizedFlags={['currencyAccountsMultiEntity']}
                          renderOn={() => <CurrencyAccounts multiEntity />}
                          renderOff={() => <CurrencyAccounts />}
                        />
                      )}
                    />
                    <Route path={routes.payments.base}>
                      <Flags
                        authorizedFlags={['paymentPageMultiEntity']}
                        renderOn={() => <Redirect to={routes.notFoundPage} />}
                        renderOff={() => <Payments />}
                      />
                    </Route>
                    <Route path={routes.payments.multientity.base}>
                      <Flags
                        authorizedFlags={['paymentPageMultiEntity']}
                        renderOn={() => <PaymentsMultiEntity />}
                        renderOff={() => <Redirect to={routes.notFoundPage} />}
                      />
                    </Route>
                    <Route
                      exact
                      path={[routes.beneficiaries]}
                      render={() => (
                        <Flags
                          authorizedFlags={['beneficiaryPageMultiEntity']}
                          renderOn={() => <Beneficiaries multiEntity />}
                          renderOff={() => <Beneficiaries />}
                        />
                      )}
                    />
                    <Route exact path={routes.noTransactions}>
                      <Flags
                        authorizedFlags={['transactionsPage']}
                        renderOn={() => <Redirect to={routes.notFoundPage} />}
                        renderOff={() => <Transactions />}
                      />
                    </Route>
                    <Route path={routes.transactions}>
                      <Flags
                        authorizedFlags={['transactionsPage']}
                        renderOn={() => <Redirect to={routes.notFoundPage} />}
                        renderOff={() => <Transactions />}
                      />
                    </Route>
                    <Route exact path={[routes.transactionsMultiEntity]}>
                      <Flags
                        authorizedFlags={['transactionsPage']}
                        renderOn={() => <TransactionsMultiEntity />}
                        renderOff={() => <Redirect to={routes.notFoundPage} />}
                      />
                    </Route>
                    <Route path={[routes.transactionsMultiEntityWithCa]}>
                      <Flags
                        authorizedFlags={['transactionsPage']}
                        renderOn={() => <TransactionsMultiEntity />}
                        renderOff={() => <Redirect to={routes.notFoundPage} />}
                      />
                    </Route>
                    <Route path={routes.profile} component={Profile} />
                    <Route exact path={routes.dashboard.base}>
                      <Flags
                        authorizedFlags={['outstandingDashboard']}
                        renderOn={() => <OutstandingActionsDashboard />}
                        renderOff={() => <Redirect to={routes.notFoundPage} />}
                      />
                    </Route>
                    <Route exact path={routes.dashboard.outstanding}>
                      <Flags
                        authorizedFlags={['outstandingDashboard']}
                        renderOn={() => <OutstandingActions />}
                        renderOff={() => <Redirect to={routes.notFoundPage} />}
                      />
                    </Route>
                    <Route exact path={routes.invoices}>
                      <Flags
                        authorizedFlags={['invoicePage']}
                        renderOn={() => (IsAuthorizedToViewInvoices
                          ? (<Invoices multiEntity />)
                          : (<Redirect to={routes.base} />
                          ))}
                        renderOff={() => (invoiceAuthorization.authorized
                          && currentAccount.invoicesEnabled
                          ? (<Invoices />) : (<Redirect to={routes.base} />
                          ))}
                      />
                    </Route>
                    <Route
                      exact
                      path={
                        [
                          routes.userManagement.base,
                          routes.userManagement.allUsers,
                          routes.userManagement.allEntities,
                          routes.userManagement.user,
                          routes.userManagement.entity,
                        ]
                      }
                    >
                      <Flags
                        authorizedFlags={['entityManagementPage']}
                        renderOn={() => <UserManagement />}
                        renderOff={() => <Redirect to={routes.base} />}
                      />
                    </Route>
                    <Route path={routes.notFoundPage}>
                      <NotFoundPageNoRedirect />
                    </Route>
                    <Redirect to={routes.notFoundPage} />
                  </Switch>
                </Page>
              </InactivityTracker>
            </PrivateRoute>
            <Auth />
          </Switch>
        </Router>
        <Notifier />
      </SnackbarProvider>
    </FlagsProvider>
  );
};

export default App;
