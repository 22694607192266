import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { AxiosError, AxiosResponse, Method } from 'axios';
import { TSignIn } from '../../models/auth';
import { TUser, TUserAccount, UserRole } from '../../models/user';
import { instance, instanceNoAccountId, instanceNoInterceptors } from '../Axios/instance';
import browserHistory from '../history/browserHistory';
import routes from '../../routes.path';
import { Buffer } from "buffer";

class AuthService {
  public static async signIn(username: string, password: string): Promise<TSignIn> {
    return await Auth.signIn(username, password);
  }

  public static async signOut() {
    return await Auth.signOut();
  }

  public static async handleInvalidSession(): Promise<void> {
    await this.signOut();
    browserHistory.push(routes.forcedSignOut);
  }

  public static async forgotPassword(username: string) {
    return await Auth.forgotPassword(username);
  }

  public static async forgotPasswordSubmit(username: string, code: string, password: string) {
    await Auth.forgotPasswordSubmit(username, code, password);
  }

  public static async newPassword(
    user: Record<string, any>, newPassword: string, oldPassword?:string,
  ) {
    try {
      await instanceNoInterceptors.post(
        '/auth-login/new-password',
        {
          oldPassword,
          newPassword,
          session: user.Session,
          username: user.username,
        },
      );
      return Auth.signIn(user.username, newPassword);
    } catch (e) {
      const errorMessage = ((e as AxiosError).response as AxiosResponse<any>)?.data.error;
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      throw new Error('An unexpected error occurred. Please contact Client Services if the error persists.');
    }
  }

  public static async changePassword(
    password: string,
    newPassword: string,
  ) {
    instanceNoAccountId.defaults.headers.post['account-id'] = 'null';
    await instanceNoAccountId.post(
      'auth/change-password',
      {
        previousPassword: password,
        proposedPassword: newPassword,
      },
    );
  }

  public static async confirmMfa(user: Record<string, any>, code: string): Promise<void> {
    return await Auth.confirmSignIn(user, code);
  }

  public static async sendCustomChallengeAnswer(user: Record<string, any>, answer: string): Promise<Record<string, unknown>> {
    return await Auth.sendCustomChallengeAnswer(user, answer);
  }

  public static async currentSession() {
    return await Auth.currentSession();
  }

  public static async currentUserInfo(): Promise<TUser | undefined> {
    const response = await Auth.currentAuthenticatedUser();
    if (response) {
      return this.mapAmplifySignInResponseToUser(response);
    }
  }

  public static async getAccessToken() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser.getSignInUserSession()?.getAccessToken().getJwtToken();
  }

  public static async getAccessTokenIssuedAt() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return +(cognitoUser.getSignInUserSession()?.getAccessToken().getIssuedAt().toFixed() as string);
  }

  public static async getAccessTokenExpiry() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return +(cognitoUser.getSignInUserSession()?.getAccessToken().getExpiration().toFixed() as string);
  }

  public static async tokenExpirationTime(): Promise<number> {
    const issuedAt = await AuthService.getAccessTokenIssuedAt();
    const expirationTime = await AuthService.getAccessTokenExpiry();
    return expirationTime - issuedAt;
  }

  public static async getIdToken() {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken();
  }

  public static async getSessionUUIDFromIdToken() {
    const idToken = await AuthService.getIdToken();

    if (idToken) {
      const decodedIdToken = JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString());

      return decodedIdToken?.sessionUUID;
    }
    return null;
  }

  public static async getAnnouncekitTokenFromIdToken() {
    const idToken = await AuthService.getIdToken();

    if (idToken) {
      const { announcekitToken } = JSON.parse(Buffer.from(idToken.split('.')[1], 'base64').toString());

      return announcekitToken;
    }
    return null;
  }

  public static async saveSessionUUIDToLocalStorage() {
    const sessionUUID = await AuthService.getSessionUUIDFromIdToken();
    const announcekitToken = await AuthService.getAnnouncekitTokenFromIdToken();

    if (sessionUUID) {
      localStorage.setItem('portal-session-id', sessionUUID);
    }
    if (announcekitToken) {
      localStorage.setItem('announcekit-token', announcekitToken);
    }
  }

  public static getSessionUUIDFromLocalStorage() {
    return localStorage.getItem('portal-session-id');
  }

  public static getAnnouncekitTokenFromLocalStorage() {
    return localStorage.getItem('announcekit-token');
  }

  public static async getEntitiesUserHasRoleFor(role: UserRole): Promise<TUserAccount[]> {
    const response: AxiosResponse<{accounts: TUserAccount[]}> = await instance(
      `/auth/user-accounts-by-role/${role.replace(/\s+/g, '').toUpperCase()}`,
    );
    if (response.status !== 200) {
      throw Error('Unable to get user accounts');
    }
    return response?.data?.accounts;
  }

  public static async setUserActive(): Promise<void> {
    const request = {
      method: 'PATCH' as Method,
      url: '/user/api/v1/activate-user',
    };
    const response = await instanceNoAccountId(request);
    if (response.status !== 200) {
      throw Error('Unable to activate user');
    }
  }

  private static mapAmplifySignInResponseToUser(response: Record<string, any>): TUser {
    const userAttributes = response.attributes;
    return {
      SubId: userAttributes.sub,
      Username: response.username,
      Email: userAttributes.email,
      Name: userAttributes.name,
      PhoneNumber: userAttributes.phone_number,
    };
  }
}
export default AuthService;
